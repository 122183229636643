.header {
  width: 12rem;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .header {
    width: 15rem;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");

html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: #ccc;
  font-size: 0.7rem;
  font-family: "Nunito Sans", sans-serif;
  background-color: #000;
}

@media (min-width: 768px) {
  .body h1 {
    font-size: 1rem;
  }
}

.CBContainer {
  width: 90%;
  margin: auto;
  padding-bottom: 0.5rem;
}

.content {
  font-weight: 900;
  font-size: 1.3rem;
  border-radius: 10px;
  border: 1px solid rgba(139, 139, 139, 0.5);
  background-color: rgba(5, 5, 5, 0.8);
}

.content h1,
h2 {
  color: #fff;
  font-family: "Orbitron", sans-serif;
  text-shadow: 2px 2px 5px #000;
}

.content h1 {
  font-size: 2rem;
}

.content h2 {
  font-size: 1.2rem;
}

.footer {
  margin: 20px auto;
  font-family: "Orbitron", sans-serif;
  font-size: 0.8rem;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid rgba(209, 0, 0, 0.5);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 768px) {
  .CBContainer {
    width: 70%;
  }

  .content h1 {
    font-size: 2.5rem;
  }

  .content h2 {
    font-size: 1.8rem;
  }
}

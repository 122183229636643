.App {
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
}

a {
  color: #fff;
  font-family: "Lobster", cursive;
  outline: none;
  text-decoration: none;
}

button {
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 84px;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 5px 2px;
  border-radius: 6px;
  border: 1px solid #000;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -44px;
  margin-bottom: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

@media (min-width: 768px) {
  .tooltip .tooltiptext {
    width: 140px;
    font-size: 1.3rem;
    margin-left: -72px;
  }
}

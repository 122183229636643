@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
.main-header {
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.9);
  border-bottom: 1px solid #fff;
  padding: 0 1rem;
  z-index: 5;
}

main {
  margin-top: 5rem;
}

@media (min-width: 768px) {
  .main-header {
    justify-content: space-between;
  }
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Lobster", cursive;
}

.nav-links li {
  color: #fff;
  margin: 1rem;
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }
}

.side-drawer {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100vh;
  width: 75%;
  background: #111;
  border-right: 1px solid #ccc;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
}

.slide-in-left-enter {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.slide-in-left-enter-active {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1;
  transition: all 500ms;
}

.slide-in-left-exit {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  opacity: 0;
  transition: all 500ms;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

.header {
  width: 12rem;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .header {
    width: 15rem;
  }
}

.main-navigation__menu-btn {
  width: 3rem;
  height: 2rem;
  background: #000;
  border: 1px solid #333;
  border-radius: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 2.2rem;
  height: 2px;
  background: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}

.HeaderContainer {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

@media (min-width: 768px) {
  .HeaderContainer {
    width: 100%;
  }
}

.FTLOC {
  font-size: 2rem;
  font-family: "Lobster", cursive;
  margin: 10px;
}

.HomeBG {
  background: url(/static/media/pageHead.d3aa530d.jpg) no-repeat top left fixed;
  background-size: cover;
}

.CBContainer {
  width: 90%;
  margin: auto;
  padding-bottom: 0.5rem;
}

.content {
  font-weight: 900;
  font-size: 1.3rem;
  border-radius: 10px;
  border: 1px solid rgba(139, 139, 139, 0.5);
  background-color: rgba(5, 5, 5, 0.8);
}

.content h1,
h2 {
  color: #fff;
  font-family: "Orbitron", sans-serif;
  text-shadow: 2px 2px 5px #000;
}

.content h1 {
  font-size: 2rem;
}

.content h2 {
  font-size: 1.2rem;
}

.footer {
  margin: 20px auto;
  font-family: "Orbitron", sans-serif;
  font-size: 0.8rem;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid rgba(209, 0, 0, 0.5);
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

@media (min-width: 768px) {
  .CBContainer {
    width: 70%;
  }

  .content h1 {
    font-size: 2.5rem;
  }

  .content h2 {
    font-size: 1.8rem;
  }
}

.AboutBG {
  background: radial-gradient(
        hsl(0, 100%, 7%) 4%,
        hsl(0, 100%, 8%) 9%,
        hsla(0, 100%, 20%, 0) 9%
      )
      0 0,
    radial-gradient(
        hsl(0, 100%, 7%) 4%,
        hsl(0, 100%, 10%) 8%,
        hsla(0, 100%, 20%, 0) 10%
      )
      50px 50px,
    radial-gradient(hsla(0, 0%, 0%, 0.8) 20%, hsla(0, 100%, 20%, 0)) 50px 0,
    radial-gradient(hsla(0, 0%, 0%, 0.8) 20%, hsla(0, 100%, 20%, 0)) 0 50px,
    radial-gradient(rgb(0, 0, 0) 35%, hsla(0, 100%, 20%, 0) 60%) 50px 0,
    radial-gradient(rgb(24, 0, 0) 35%, hsla(0, 100%, 20%, 0) 60%) 100px 50px,
    radial-gradient(hsla(0, 100%, 5%, 0.7), hsla(0, 100%, 20%, 0)) 0 0,
    radial-gradient(hsla(0, 100%, 5%, 0.7), hsla(0, 100%, 20%, 0)) 50px 50px,
    linear-gradient(
        45deg,
        hsla(0, 100%, 20%, 0) 49%,
        hsla(0, 100%, 0%, 1) 50%,
        hsla(0, 100%, 20%, 0) 70%
      )
      0 0,
    linear-gradient(
        -45deg,
        hsla(0, 100%, 20%, 0) 49%,
        hsla(0, 100%, 0%, 1) 50%,
        hsla(0, 100%, 20%, 0) 70%
      )
      0 0;
  background-color: #100;
  background-size: 100px 100px;
}

.AboutContent {
  background: url(/static/media/AndyJacko.4c9d4bd4.png) no-repeat bottom right;
  background-size: 100%;
  padding: 0.5rem;
}

.particles {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

@media (min-width: 768px) {
  .AboutContent {
    background-size: contain;
  }
}

.skills {
  width: 74px;
  height: 74px;
  text-align: center;
  display: inline-block;
  opacity: 0.4;
}

.skills:hover {
  opacity: 1;
}

.skills img {
  width: 64px;
}

.SkillsBG {
  background-color: #000000;
  opacity: 1;
  background-image: repeating-linear-gradient(
      45deg,
      #250000 25%,
      transparent 25%,
      transparent 75%,
      #250000 75%,
      #250000
    ),
    repeating-linear-gradient(
      45deg,
      #250000 25%,
      #000000 25%,
      #000000 75%,
      #250000 75%,
      #250000
    );
  background-position: 0 0, 19px 19px;
  background-size: 38px 38px;
}

.SocialItem {
  display: inline-block;
}

.fa {
  padding: 5px 10px;
}

.fa:hover {
  color: #990000;
}

.ContactBG {
  background: linear-gradient(45deg, #350000 20%, #100000, #050000, #000000);
  background-size: 600% 600%;
  -webkit-animation: gradient 5s ease-in-out infinite;
          animation: gradient 5s ease-in-out infinite;
}

.ContactContent {
  background: url(/static/media/AndyJacko2.dc6a682b.png) no-repeat bottom left;
  background-size: 100%;
  padding: 0.5rem;
}

.disclaimer {
  font-size: 0.7rem;
  color: #666;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (min-width: 361px) {
  .ContactContent {
    background-size: contain;
  }
}

.App {
  text-align: center;
}

.center {
  display: flex;
  justify-content: center;
}

a {
  color: #fff;
  font-family: "Lobster", cursive;
  outline: none;
  text-decoration: none;
}

button {
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 84px;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  text-align: center;
  padding: 5px 2px;
  border-radius: 6px;
  border: 1px solid #000;
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -44px;
  margin-bottom: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 1s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

@media (min-width: 768px) {
  .tooltip .tooltiptext {
    width: 140px;
    font-size: 1.3rem;
    margin-left: -72px;
  }
}

html {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: #ccc;
  font-size: 0.7rem;
  font-family: "Nunito Sans", sans-serif;
  background-color: #000;
}

@media (min-width: 768px) {
  .body h1 {
    font-size: 1rem;
  }
}


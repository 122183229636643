.ContactBG {
  background: linear-gradient(45deg, #350000 20%, #100000, #050000, #000000);
  background-size: 600% 600%;
  animation: gradient 5s ease-in-out infinite;
}

.ContactContent {
  background: url("../../assets/AndyJacko2.png") no-repeat bottom left;
  background-size: 100%;
  padding: 0.5rem;
}

.disclaimer {
  font-size: 0.7rem;
  color: #666;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (min-width: 361px) {
  .ContactContent {
    background-size: contain;
  }
}
